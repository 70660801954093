
import {
  Vue, Component, Prop, Watch
} from 'vue-property-decorator';
import PostId from '@/model/posts/PostId';
import CommentMessage from '@/model/posts/comment/CommentMessage';
import CommentPost from '@/model/posts/comment/CommentPost';
import InformationApi from '@/module/api/InformationApi';
import UploadFileContainer from '@/model/type/UploadFileContainer';
import FileInfo from '@/model/type/FileInfo';
import FileUpload from '@/components/Parts/FileUpload.vue';
import CommentId from '@/model/posts/comment/CommentId';
import UserBaseInfo from '@/model/user/UserBaseInfo';

@Component({
  components: {
    FileUpload
  }
})
export default class CommentPostView extends Vue {
  /** 自身コメントID */
  @Prop({ default: () => CommentId.empty() }) commentId?: CommentId;
  /** 元投稿ID */
  @Prop() origin?: PostId;
  /** 親コメントID */
  @Prop({ default: () => CommentId.empty() }) parent?: CommentId;
  /** ログインユーザー */
  @Prop() user?: UserBaseInfo;

  commentMessage: CommentMessage = new CommentMessage();
  // 追加ファイル
  addingFiles:UploadFileContainer[]=[];
  // 保存済みファイル
  savedFileList:FileInfo[]=[];
  // 削除ファイル
  deleteList:string[]=[];
  // uploadファイルのカウンター v-for用
  sequence: number=0;
  showDialog = false;
  showDraftDialog = false;

  mounted(): void {
    this.getLocalStorage();
  }

  @Watch('origin')
  loaded(): void {
    if (this.origin) {
      this.getLocalStorage();
    }
  }

  changeItem():void {
    if (this.parent?.isEmpty()) {
      localStorage.setItem(`comment${this.origin?.value}`, JSON.stringify(this.commentMessage));
      return;
    }
    localStorage.setItem(`comment${this.parent?.value}`, JSON.stringify(this.commentMessage));
  }

  getLocalStorage():void{
    if (this.parent?.isEmpty()) {
      const commentData = localStorage.getItem(`comment${this.origin?.value}`);
      if (commentData === null) return;
      this.commentMessage = CommentMessage.clone(JSON.parse(commentData));
      return;
    }
    const commentData = localStorage.getItem(`comment${this.parent?.value}`);
    if (commentData === null) return;
    this.commentMessage = CommentMessage.clone(JSON.parse(commentData));
  }

  deleteLocalStorage():void{
    if (this.parent?.isEmpty()) {
      localStorage.removeItem(`comment${this.origin?.value}`);
      return;
    }
    localStorage.removeItem(`comment${this.parent?.value}`);
  }

  async postDraft():Promise<string> {
    const comment = new CommentPost(this.commentId, this.commentMessage, this.origin, this.parent);
    const res = await InformationApi.postCommentDraft(comment);
    const postId = Number(res.data.value);
    await this.fileUpload(postId);

    this.deleteLocalStorage();
    this.showDraftDialog = false;
    return '';
  }

  async postComment():Promise<string> {
    const comment = new CommentPost(this.commentId, this.commentMessage, this.origin, this.parent);
    const res = await InformationApi.postComment(comment);
    const postId = Number(res.data.value);
    await this.fileUpload(postId);

    this.showDialog = false;

    const message:CommentMessage = new CommentMessage(this.commentMessage.value);

    this.rtnComment(message, new CommentId(postId));
    this.commentMessage.value = '';
    this.addingFiles = [];
    this.deleteLocalStorage();
    return '';
  }

  async fileUpload(postId: number):Promise<string> {
    // 添付ファイルをアップロード
    const data = this.createUploadData(postId);

    // ファイルアップロード
    await InformationApi.upload(data).catch((err:Error) => {
      this.$root.$emit('event-show-snackbar-infinity', 'ファイルアップロードに失敗しました。後程ファイルをアップロードし直してください。');
      throw err;
    });
    return '';
  }

  rtnComment(commentMessage:CommentMessage, commentId: CommentId):void {
    this.$emit('rtnComment', { commentMessage, commentId });
  }

  /**
   * アップロード用FormData作成
   */
  private createUploadData(postId: number): FormData {
    // ファイルアップロード
    const data = new FormData();
    data.append('postId', postId.toString());

    // 登録ファイル
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.addingFiles.length; i++) {
      const f = this.addingFiles[i].file;
      if (f) {
        data.append('postfiles', f);
      }
    }

    // 削除ファイル
    // eslint-disable-next-line no-plusplus
    for (let k = 0; k < this.deleteList.length; k++) {
      const h = this.deleteList[k];
      if (h) {
        data.append('deleteFiles', h);
      }
    }
    return data;
  }
}
