
import { Component, Prop, Vue } from 'vue-property-decorator';
import CommentDetail from '@/model/posts/comment/CommentDetail';
import MessageShowBox from '@/components/post/MessageShowBox.vue';
import CommentBodyView from '@/components/post/CommentBodyView.vue';
import CommentPostView from '@/components/post/CommentPostView.vue';
import PostId from '@/model/posts/PostId';
import UserBaseInfo from '@/model/user/UserBaseInfo';
import CommentId from '@/model/posts/comment/CommentId';

@Component({
  components: {
    MessageShowBox,
    CommentBodyView,
    CommentPostView
  }
})
export default class CommentListView extends Vue {
 @Prop() comment!: CommentDetail;
  /** 元投稿ID */
  @Prop() origin?: PostId;
  @Prop() userBaseInfo?:UserBaseInfo;
 isShowBody = false;
 switchShow(status:boolean):void {
   this.isShowBody = status;
 }

 rtnComment(event:any):void{
   if (this.userBaseInfo && this.origin) {
     const commentDetail = CommentDetail.create(event.commentId, this.userBaseInfo, event.commentMessage, this.origin);
     this.comment.childComment.push(commentDetail);
   }
 }

 deleteParentComment(paramCommentId:CommentId):void{
   this.$emit('deleteComment', paramCommentId);
 }

 deleteChildComment(paramCommentId:CommentId):void{
   this.comment.childComment = this.comment.childComment.filter((comment) => comment.body.commentId.value !== paramCommentId.value);
 }
}
