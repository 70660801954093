
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import CommentDetail from '@/model/posts/comment/CommentDetail';
import FileInfo from '@/model/type/FileInfo';
import InformationApi from '@/module/api/InformationApi';
import MessageShowBox from '@/components/post/MessageShowBox.vue';
import UserBaseInfo from '@/model/user/UserBaseInfo';
import PostReceiverReadStatus from '@/model/posts/PostReceiverReadStatus';

@Component({
  components: {
    MessageShowBox,
  }
})
export default class CommentBodyView extends Vue {
  @Prop({ default: () => new CommentDetail() }) comment!:CommentDetail;
  defaultOpen: boolean = false;
  @State('loginUser') loginUser!: UserBaseInfo;
  @Getter('isAdminGroup')isAdminGroup!: boolean;
  files: FileInfo[] = []
  isShowBody=false;
  showDialog=false;
  viewerReadStatus: PostReceiverReadStatus =new PostReceiverReadStatus();

  mounted(): void {
    // ハッシュでmonthreportIdが指定されている場合
    let { hash } = window.location;
    let hashId:number = -1;
    if (hash) {
      hashId = parseInt(hash.split('-')[1], 10);
    } else {
      hash = '';
    }

    if (hashId === this.comment.body.commentId.value) {
      this.switchShow();
      setTimeout(() => {
        window.location.href = hash;
      }, 100);
    }
    if (this.loginUser.userId.value === this.comment.body.registerId.value) {
      InformationApi.commentReadStatus(this.comment.body.commentId)
        .then((res) => {
          this.viewerReadStatus = res.data;
        });
    }
  }

  async switchShow():Promise<void> {
    this.isShowBody = !this.isShowBody;
    this.$emit('switchShow', this.isShowBody);
    // 表示するときにファイル取得
    if (this.isShowBody) {
      // 添付ファイル取得 & 既読
      const resFiles = await InformationApi.getSavedCommentFiles(this.comment.body.originPostId.value, this.comment.body.commentId.value);
      this.files = FileInfo.clone(resFiles.data);
    }
  }

  expandButtonName(): string {
    if (this.isShowBody) {
      return 'コメントを閉じる';
    }
    return 'コメントを開く';
  }

  /**
   * 自分の投稿したコメントか
   */
  isMyPost(): boolean {
    return this.comment.body.registerId.value === this.loginUser.userId.value;
  }

  deleteComment(): void {
    InformationApi.deleteComment(this.comment.body.commentId)
      .then(() => {
        this.showDialog = false;
        this.$emit('deleteComment', this.comment.body.commentId);
        this.$root.$emit('event-show-snackbar', 'コメントを削除しました');
      });
  }

  toUserProfile(): void {
    this.$router.push(`/userprofile/${this.comment.body.registerId.value}`);
  }
}
