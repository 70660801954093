import PostId from '../PostId';
import CommentId from './CommentId';
import CommentMessage from './CommentMessage';

export default class CommentPost {
  commentId: CommentId;

  /** コメント本文 */
  message: CommentMessage;

  /** 元投稿のID */
  origin: PostId;

  /** 親コメントのID */
  parent: CommentId;

  constructor(
    commentId?: CommentId,
    message?:CommentMessage,
    origin?: PostId,
    parent?: CommentId
  ) {
    this.commentId = commentId || new CommentId();
    this.message = message || new CommentMessage();
    this.origin = origin || new PostId();
    this.parent = parent || new CommentId();
  }
}
