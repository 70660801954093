export default class PostReceiverReadStatus {
  value: string;
  isReaded: boolean;
  constructor(value?: string, isReaded?: boolean) {
    this.value = (value === undefined ? '' : value);
    this.isReaded = (isReaded === undefined ? false : isReaded);
  }

  public static clone(source:PostReceiverReadStatus):PostReceiverReadStatus {
    const result = new PostReceiverReadStatus();
    result.value = source.value;
    return result;
  }
}
